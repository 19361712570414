import { listItem, breadcrumbList } from "schema-comsat"

export function convertBreadcrumb (rootUrl, breadcrumbs) {
	return breadcrumbList({
    itemListElement: breadcrumbs.map((item, n) => {
      return listItem({
        position: n + 1,
        name: item.name,
        item: rootUrl + item.url,
      })
    })
  });
}